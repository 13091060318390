import { Link, Columns, Edit2, ChevronLeft, Twitter } from 'react-feather'
import { copyValueToClipBoard } from '@/utils/index'
import analytics from '@/utils/analytics'
import toast from 'react-hot-toast'
import { useSession } from 'next-auth/client'
import api from '@/utils/api'
import { useRecoilState } from 'recoil'
import {
  modalState,
  annotationsViewState,
  useIsRowView,
  useRecoilStateWithLocalStorage,
} from '@/store'
import VideoMoreOptions from '@/components/AnnotationsView/panel/VideoMoreOptions'
import ReactTooltip from 'react-tooltip'

export default function VideoOptions({
  video,
  annotations,
  isDemo,
  title,
  setTitle,
  setIsHidden,
}) {
  const [view, setView] = useRecoilStateWithLocalStorage({
    state: annotationsViewState,
    defaultValue: 'row',
  })
  const [, setModal] = useRecoilState(modalState)
  const [session] = useSession()
  const isUserVideo = session && session.user._id === video.user
  const isRowView = useIsRowView()
  const viewIconClassName = isRowView ? '' : 'transform rotate-90'

  function changeView() {
    const newView = view === 'row' ? 'column' : 'row'
    analytics.track(`${isDemo ? '(Demo) ' : ''}Changing View`, { newView })
    setView(newView)

    if (newView === 'column') {
      setIsHidden(true)
    }
  }

  function showTitleModal() {
    setModal({
      isEdit: true,
      title: 'Update Title',
      value: title,
      onClick: (e, newTitle) => updateTitle(newTitle),
    })
  }

  function updateTitle(title) {
    try {
      analytics.track(`${isDemo ? '(Demo) ' : ''}Updating video title`)

      if (isDemo) {
        localStorage.setItem(
          'demoVideo',
          JSON.stringify({
            ...video,
            title,
          })
        )
      } else {
        api.put(`/videos/${video._id}`, {
          title,
        })
      }

      setTitle(title)

      // setIsUpdatingTitle(false)
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  function copyLinkToShare() {
    if (isDemo) {
      toast.error("You can't share a demo video")
      return
    }

    const publicVideoUrl = getPublicVideoUrl(video)
    copyValueToClipBoard(publicVideoUrl)
    analytics.track('Public link copied')
    toast.success('Public link copied')
  }

  function hidePanel() {
    analytics.track(`${isDemo ? '(Demo) ' : ''}Hiding panel`)
    setIsHidden(true)
  }

  function shareVideoOnTwitter() {
    const baseUrl = `https://twitter.com/intent/tweet?text=`
    const text = getTwitterVideoDescription(video)
    const url = `${baseUrl}${text}`
    window.open(url, '_blank')
    analytics.track('Video shared on Twitter')
  }

  return (
    <>
      <div className="flex w-full justify-start">
        {isUserVideo && (
          <Option
            icon={<Edit2 className="h-5 w-5 text-primary" />}
            label="Edit title"
            onClick={showTitleModal}
          />
        )}

        <div className="hidden md:flex">
          <Option
            icon={
              <Columns
                className={`h-5 w-5 text-primary ${viewIconClassName}`}
                style={{ transition: 'transform .3s ease-in-out' }}
              />
            }
            label="Change view layout"
            onClick={changeView}
          />
        </div>

        <Option
          icon={<Link className="h-5 w-5 text-primary" />}
          label="Copy public link"
          onClick={copyLinkToShare}
        />

        <Option
          icon={<Twitter className="h-5 w-5 text-primary" />}
          label="Share on Twitter"
          onClick={shareVideoOnTwitter}
        />

        <VideoMoreOptions {...{ video, annotations }} />
      </div>

      <div className="hidden lg:flex w-full justify-end -mr-2">
        <Option
          icon={<ChevronLeft className="h-5 w-5 text-primary" />}
          onClick={hidePanel}
        />
      </div>
    </>
  )
}

const buttonSize = '32px'

function Option({ className = '', icon, label = '', onClick }) {
  const uniqueLabel = label.split(' ').join('-')

  return (
    <>
      <div
        className={`flex center mr-2 px-1 py-1 bg-lighterblue text-primary rounded-md clickable-opacity ${className}`}
        style={{ width: buttonSize, height: buttonSize }}
        data-tip
        data-for={uniqueLabel}
        onClick={onClick}
      >
        {icon}
      </div>
      {label && (
        <ReactTooltip
          arrowColor="transparent"
          uuid={uniqueLabel}
          id={uniqueLabel}
          place="bottom"
          effect="solid"
        >
          {label}
        </ReactTooltip>
      )}
    </>
  )
}

function getPublicVideoUrl(video) {
  const baseUrl = document.location.origin
  return `${baseUrl}/watch/${encodeURIComponent(video.slug ?? video._id)}`
}

function getTwitterVideoDescription(video) {
  const publicVideoUrl = getPublicVideoUrl(video)
  const encodedTitle = encodeURIComponent(video.title)
  return `${encodedTitle} - ${publicVideoUrl} via @annotate_tv`
}
