import React from 'react'
import { useEffect, useMemo } from 'react'
import Script from 'next/script'
import { useSession } from 'next-auth/client'
import analytics from '@/utils/analytics'
import { useSettings } from '@/pages/settings'
import PLANS from '@/utils/plans'

export default function Splitbee() {
  const [session] = useSession()
  const [settings] = useSettings()

  const planType = useMemo(() => {
    if (!settings?.billing || settings?.billing?.cancellationEffectiveDate) {
      return undefined
    }

    const currentPlan = Object.values(PLANS).find(
      (plan) => plan.id === settings?.billing?.planId
    )

    return currentPlan?.name
  }, [settings?.billing])

  useEffect(() => {
    if (session && session.user) {
      const user = session.user
      analytics.identify({
        USER_ID: user._id,
        $name: user.name,
        $email: user.email,
        createdAt: user.createdAt,
        planType,
      })
    }
  }, [session, planType])

  return <Script data-api="/_hive" src="/bee.js" strategy="afterInteractive" />
}
