import { useEffect, useState, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { mdParser, getTimeFromSeconds } from '@/utils/index'
import api from '@/utils/api'
import {
  deleteItem,
  currentAnnotationState,
  modalState,
  isAnnotationsMenuOpenState,
  ocrState,
  isHiddenPanelState,
  useRecoilStateWithLocalStorage,
} from '@/store'
import { Clock, Inbox, FileText, ChevronRight } from 'react-feather'
import DOMPurify from 'dompurify'
import analytics from '@/utils/analytics'
import toast from 'react-hot-toast'
import Input from '@/components/Input'
import VideoOptions from '@/components/AnnotationsView/panel/VideoOptions'
import AnnotationOptions from '@/components/AnnotationsView/panel/AnnotationOptions'
import Logo from 'public/img/logos/logo.svg'
import Panel from '@/components/Panel'
import classNames from 'classnames'
import { useHotKeys } from '@/utils/shortcuts'

const panelSize = '300px'

export default function AnnotationsPanelWrapper(props) {
  const [isOpen, setIsOpen] = useRecoilState(isAnnotationsMenuOpenState)

  return (
    <>
      <AnnotationsPanel {...{ ...props, className: 'hidden lg:block' }} />
      <Panel {...{ isOpen, setIsOpen }}>
        <AnnotationsPanel {...props} isMobile setIsOpen={setIsOpen} />
      </Panel>
    </>
  )
}

export function AnnotationsPanel({
  video,
  annotations,
  setAnnotations,
  isReadOnly,
  isDemo = false,
  className = '',
  isMobile = false,
  setIsOpen,
}) {
  const [ocr] = useRecoilState(ocrState)
  const [, showDeleteModal] = useRecoilState(modalState)
  const [isHidden, setIsHidden] = useRecoilStateWithLocalStorage({
    state: isHiddenPanelState,
    defaultValue: false,
  })
  const [search, setSearch] = useState('')
  const [title, setTitle] = useState(video.title)
  const [currentAnnotation, setCurrentAnnotation] = useRecoilState(
    currentAnnotationState
  )

  useHotKeys('CmdOrCtrl+\\', () => setIsHidden((isHidden) => !isHidden))

  const filteredAnnotations = useMemo(() => {
    if (!annotations) {
      return []
    }

    const query = search.toLocaleLowerCase()
    return annotations?.filter(({ title, content }) => {
      if (!query) {
        return true
      }

      return (
        title.toLocaleLowerCase().includes(query) ||
        content.toLocaleLowerCase().includes(query)
      )
    })
  }, [annotations, search])

  useEffect(() => {
    if (!isDemo) {
      setCurrentAnnotation(null)
    }
    setTitle(video.title)
  }, [video._id, isDemo])

  const showDeleteConfirmModal = (e, annotationId) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    showDeleteModal({
      isConfirm: true,
      title: 'Delete Annotation',
      body: 'Are you sure you want to delete this annotation?',
      onClick: () => onDelete(annotationId),
    })
  }

  function onDelete(annotationID) {
    try {
      analytics.track(`${isDemo ? '(Demo) ' : ''}Deleting annotation`)

      if (!isDemo) {
        api.delete(`/annotations/${annotationID}`)
      }

      if ((currentAnnotation && currentAnnotation._id) === annotationID) {
        setCurrentAnnotation(null)
      }

      setAnnotations((prev) =>
        deleteItem({
          items: prev,
          itemID: annotationID,
        })
      )
    } catch (error) {
      analytics.track(`${isDemo ? '(Demo) ' : ''}Error deleting annotation`)
      toast.error('Something went wrong')
    }
  }

  const isEmpty = filteredAnnotations?.length === 0

  const style = isMobile
    ? { zIndex: 40 }
    : {
        width: panelSize,
        transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        zIndex: ocr ? 2 : 41,
        ...(isHidden
          ? {
              width: 0,
            }
          : {}),
      }

  return (
    <div
      style={style}
      className={`h-screen bg-lightGray shadow-md relative ${className}`}
    >
      {isHidden && (
        <div
          className="absolute flex center px-1 py-1 bg-lighterblue text-primary clickable rounded-r-sm"
          style={{ right: '-40px', width: '40px', height: '40px', top: '32px' }}
          onClick={() => setIsHidden(false)}
        >
          <ChevronRight className="w-full h-full" />
        </div>
      )}

      <div
        className={`transition-opacity duration-300 ${
          isHidden ? 'opacity-0 pointer-events-none' : 'opacity-100'
        }`}
      >
        <div className="flex justify-start items-center pt-3 px-2">
          <h2 className="truncate text-xl font-semibold" title={title}>
            {title}
          </h2>
        </div>

        <div className="flex justify-start items-center pt-3 px-2">
          <VideoOptions
            {...{
              video,
              annotations,
              isDemo,
              title,
              setTitle,
              setIsHidden,
            }}
          />
        </div>

        {annotations?.length > 0 && (
          <div className="search-wrapper px-2 pt-3 pb-0">
            <Input
              placeholder="Search"
              type="search"
              value={search}
              tabIndex="-1"
              onChange={({ target }) => setSearch(target.value)}
            />
          </div>
        )}

        {isEmpty && (
          <div className="empty-wrapper center">
            <Empty />
          </div>
        )}

        {!isEmpty && (
          <div
            className={`annotations-list scroll-mask px-2 py-3 ${
              isReadOnly ? 'has-powered-by' : ''
            }`}
          >
            {filteredAnnotations?.map((annotation) => (
              <Annotation
                key={annotation._id}
                annotation={annotation}
                currentAnnotation={currentAnnotation}
                setCurrentAnnotation={setCurrentAnnotation}
                isDemo={isDemo}
                isReadOnly={isReadOnly}
                onDelete={showDeleteConfirmModal}
                setIsOpen={setIsOpen}
                videoId={video._id}
              />
            ))}
          </div>
        )}

        {isReadOnly && !isEmpty && (
          <div className="center cursor-pointer p-2">
            <a
              href="https://annotate.tv"
              rel="noopener noreferrer"
              target="_blank"
            >
              <h4 className="center text-sm clickable-opacity font-semibold">
                Powered by
                <Logo
                  style={{
                    width: '17px',
                    height: '17px',
                    marginLeft: '10px',
                    marginRight: '5px',
                  }}
                />
                Annotate.tv
              </h4>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

function Annotation({
  videoId,
  annotation,
  currentAnnotation,
  setCurrentAnnotation,
  isDemo,
  isReadOnly,
  onDelete,
  setIsOpen,
}) {
  const isActive = currentAnnotation?._id === annotation._id

  function onClick() {
    analytics.track(`${isDemo ? '(Demo) ' : ''}Annotation selected`)
    if (setIsOpen) {
      setIsOpen(false)
    }
    setCurrentAnnotation(annotation)
  }

  const activeClass = 'bg-hoverblue text-white'
  const className = classNames(
    'annotation group cursor-pointer transition rounded-md flex flex-col bg-darkerblue p-2 mb-2 hover:bg-hoverblue hover:text-white',
    { [activeClass]: isActive }
  )

  return (
    <div style={{ height: '100px' }} className={className} onClick={onClick}>
      <div
        className="flex items-center"
        style={{ height: '20px', minHeight: '20px' }}
      >
        <div className="center">
          <Clock
            className="mr-2"
            style={{ width: '15px', height: '15px', maxWidth: '15px' }}
          />
          <span className="text-sm">
            {getTimeFromSeconds(annotation.start)}
          </span>
        </div>

        <div className="w-full flex justify-end">
          <AnnotationOptions
            {...{ videoId, isReadOnly, annotation, onDelete }}
          />
        </div>
      </div>

      {annotation.title && (
        <div className="my-1">
          <h3 className="truncate font-semibold">{annotation.title}</h3>
        </div>
      )}

      <div
        className="truncate text-md"
        dangerouslySetInnerHTML={{
          __html: getTextFromHtml({
            markdown: annotation.content || '',
          }),
        }}
      />
    </div>
  )
}

function Empty() {
  return (
    <div className="p-6 flex flex-col center h-full w-full pt-14">
      <Inbox
        className="mb-3 text-gray-500 stroke-1"
        style={{ width: '50px', height: '50px' }}
      />
      <span className="text-md text-gray-500">No Annotations</span>
    </div>
  )
}

export function MobileAnnotationsButton() {
  const [isOpen, setIsOpen] = useRecoilState(isAnnotationsMenuOpenState)

  return (
    <div className="bg-primary rounded-full annotations-menu-button">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="h-12 w-12 inline-flex items-center justify-center rounded-md text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
      >
        <span className="sr-only">Open sidebar</span>
        <FileText className="h-6 w-6" />
      </button>
    </div>
  )
}

function getTextFromHtml({ markdown }) {
  let element = document.createElement('span')
  element.innerHTML = DOMPurify.sanitize(mdParser.render(markdown))
  const text = element.textContent.slice(0, 100)
  element = null
  return text
}
