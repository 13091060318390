import hljs from 'highlight.js'
import MarkdownIt from 'markdown-it'
import differenceInDays from 'date-fns/differenceInDays'
import { parse } from 'tinyduration'

const ytKey = 'AIzaSyAgZz7KTMjJ4zaPvK22Wkf2F9zb6UA1r-8'

export const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value
      } catch (e) {
        //
      }
    }
    return ''
  },
})

export function getRandomID() {
  return Math.random().toString(36).substr(2, 9)
}

export function getTimeFromSeconds(seconds: number) {
  const time = new Date(1000 * +seconds).toISOString().substr(11, 8)

  if (time.startsWith('00:')) {
    return time.substr(3)
  } else {
    return time
  }
}

export function getSecondsFromTime(time: string) {
  const hasHours = countCharacter(':', time) === 2
  const splitted = time.split(':')
  let acc = 0

  if (hasHours) {
    const [hours, minutes, seconds] = splitted
    acc += +hours * 3600
    acc += +minutes * 60
    acc += +seconds
    return acc
  } else {
    const [minutes, seconds] = splitted
    acc += +minutes * 60
    acc += +seconds
    return acc
  }
}

// P1DT7H54M31S

// Duration is in ISO 8601
export function getSecondsFromDuration(duration: string) {
  const { days = 0, hours = 0, minutes = 0, seconds = 0 } = parse(duration)
  return days * 86400 + hours * 3600 + minutes * 60 + seconds

  // const regex = /^PT(?:(\d+\.*\d*)H)?(?:(\d+\.*\d*)M)?(?:(\d+\.*\d*)S)?$/

  // let hours = 0
  // let minutes = 0
  // let seconds = 0

  // if (regex.test(duration)) {
  //   const matches = regex.exec(duration)
  //   if (matches[1]) {
  //     hours = Number(matches[1])
  //   }
  //   if (matches[2]) {
  //     minutes = Number(matches[2])
  //   }
  //   if (matches[3]) {
  //     seconds = Number(matches[3])
  //   }
  //   return hours * 3600 + minutes * 60 + seconds
  // }
}

export function copyValueToClipBoard(value: string) {
  const el = document.createElement('textarea')
  el.value = value
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export function countCharacter(char: string, string: string) {
  return string.split('').reduce((acc, ch) => (ch === char ? acc + 1 : acc), 0)
}

export function getYouTubeID(fullUrl: string) {
  let ID: string | string[] = ''

  const url = fullUrl
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)

  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i)
    ID = ID[0]
  } else {
    ID = url
  }

  return ID
}

export function isVimeoUrl(url: string) {
  return url.includes('vimeo.com')
}

export async function getVimeoVideoData(url: string) {
  try {
    const baseUrl = 'https://vimeo.com/api/oembed.json?url='

    const response = await fetch(`${baseUrl}${url}`)
    const data = await response.json()

    return {
      title: data.title,
      duration: data.duration,
      thumbnails: {
        default: {
          url: data.thumbnail_url,
          width: data.thumbnail_width,
          height: data.thumbnail_height,
        },
      },
      description: data.description,
      channelId: data.author_url,
      channelTitle: data.author_name,
      categoryId: '',
      originalTags: [],
    }
  } catch (error) {
    return null
  }
}

export function isYoutubeUrl(url: string) {
  const regex =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  if (url.match(regex)) {
    return url.match(regex)?.[1]
  }
  return false
}

export function getYouTubePlaylistID(url: string) {
  const reg = new RegExp('[&?]list=([a-z0-9_-]+)', 'i')
  const match = reg.exec(url)
  if (match && match[1]) {
    return match[1]
  }

  return null
}

export async function getYouTubePlaylistData(id: string) {
  const response = await fetch(
    `https://www.googleapis.com/youtube/v3/playlists?id=${id}&key=${ytKey}&part=id,snippet,contentDetails`
  )

  const data = await response.json()

  if (!data.items.length) {
    return null
  }

  const item = data.items[0]
  const snippet = item.snippet

  return {
    title: snippet.title,
    thumbnails: snippet.thumbnails,
    description: snippet.description,
    channelId: snippet.channelId,
    channelTitle: snippet.channelTitle,
  }
}

// Short playlist: https://www.youtube.com/watch?v=WosuZOsWqlc&list=PL9THAs3oLCl6WBG6LXxBMPKGxRqo1g9Cc
// Big playlist: https://www.youtube.com/playlist?list=PLMrJAkhIeNNQV7wi9r7Kut8liLFMWQOXn
export async function getYouTubePlaylistItemsId({
  playlistId,
  nextPageToken,
  items = [],
}: {
  playlistId: string
  nextPageToken: string
  items: { id: string; position: number }[]
}) {
  const data = await getYouTubePlaylistItemsIdPerPage({
    playlistId,
    pageToken: nextPageToken,
  })

  const newItems = data.items.map(({ contentDetails, snippet }: { contentDetails: any, snippet: any }) => ({
    id: contentDetails.videoId,
    position: snippet.position,
  }))

  const _items = [...items, ...newItems]

  const totalResults = data.pageInfo.totalResults
  const resultsPerPage = data.pageInfo.resultsPerPage
  const hasMoreThanOnePage = totalResults > resultsPerPage

  if (!hasMoreThanOnePage) {
    return _items
  }

  if (_items.length === totalResults || !data.nextPageToken) {
    return _items
  }

  return await getYouTubePlaylistItemsId({
    playlistId,
    nextPageToken: data.nextPageToken,
    items: _items,
  })
}

export async function getYouTubePlaylistItemsIdPerPage({
  playlistId,
  pageToken,
}: {
  playlistId: string
  pageToken: string
}) {
  const response = await fetch(
    `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${playlistId}&key=${ytKey}${
      pageToken ? `&pageToken=${pageToken}` : ''
    }&maxResults=50&part=id,snippet,contentDetails`
  )

  const data = await response.json()

  return data
}

export async function getYouTubeVideoData({ id, position = 0 }: { id: string, position: number }) {
  const response = await fetch(
    `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${ytKey}&part=snippet,contentDetails`
  )

  const data = await response.json()

  if (!data.items.length) {
    return null
  }

  const item = data.items[0]
  const duration = getSecondsFromDuration(item.contentDetails.duration)
  const snippet = item.snippet

  return {
    id: item.id,
    title: snippet.title,
    duration: duration,
    thumbnails: snippet.thumbnails,
    description: snippet.description,
    channelId: snippet.channelId,
    channelTitle: snippet.channelTitle,
    categoryId: snippet.categoryId,
    originalTags: snippet.tags,
    position,
  }
}

export function hideAllTooltips() {
  const allTooltips = document.querySelectorAll(
    '.__react_component_tooltip.show'
  )
  allTooltips.forEach((tooltip) => {
    tooltip.classList.remove('show')
  })
}

export const demoVideo = {
  _id: getRandomID(),
  url: 'https://youtu.be/Bw9P_ZXWDJU',
  platform: 'youtube',
  title:
    '21 Lessons for the 21st Century | Yuval Noah Harari | Talks at Google',
  duration: 3528,
  thumbnails: {
    default: {
      url: 'https://i.ytimg.com/vi/Bw9P_ZXWDJU/default.jpg',
      width: 120,
      height: 90,
    },
    medium: {
      url: 'https://i.ytimg.com/vi/Bw9P_ZXWDJU/mqdefault.jpg',
      width: 320,
      height: 180,
    },
    high: {
      url: 'https://i.ytimg.com/vi/Bw9P_ZXWDJU/hqdefault.jpg',
      width: 480,
      height: 360,
    },
    standard: {
      url: 'https://i.ytimg.com/vi/Bw9P_ZXWDJU/sddefault.jpg',
      width: 640,
      height: 480,
    },
    maxres: {
      url: 'https://i.ytimg.com/vi/Bw9P_ZXWDJU/maxresdefault.jpg',
      width: 1280,
      height: 720,
    },
  },
  description:
    'Yuval Noah Harari, macro-historian, Professor, best-selling author of "Sapiens" and "Homo Deus," and one of the world\'s most innovative and exciting thinkers, discusses his newest work, "21 Lessons for the 21st Century."\n\nDescribed as a “truly mind-expanding” journey through today’s most pressing issues, "21 Lessons for the 21st Century" reminds us to maintain our collective focus in the midst of dizzying and disorienting change. \n\nModerated by Wilson White.\n\nGet the book: https://goo.gl/CVDJzG\n\nVisit Yuval Noah Harari\'s YouTube channel: https://www.youtube.com/user/YuvalNoahHarari',
  channelId: 'UCbmNph6atAoGfqLoCL_duAg',
  channelTitle: 'Talks at Google',
  categoryId: '22',
  originalTags: [
    'talks at google',
    'ted talks',
    'inspirational talks',
    'educational talks',
    '21 Lessons for the 21st Century',
    'Yuval Noah Harari',
    '21st century lessons',
    '21 lessons',
    '21st Century',
    'lesson to learn',
  ],
}

const privateRoutes = ['/videos', '/playlists', '/new', '/settings', '/upgrade']

export function getIsPrivate(pathname: string) {
  return privateRoutes.some((item) => pathname.includes(item))
}

export function getIsPremium({ billing, user }: { billing: any, user: any }) {
  const serverIsPremium = isActiveBilling(billing)
  const isTrial = getIsTrial(user)
  return serverIsPremium || isTrial
}

export function isActiveBilling(billing: any) {
  if (!billing) {
    return false
  }

  const { cancellationEffectiveDate } = billing

  if (cancellationEffectiveDate) {
    const today = new Date().toLocaleDateString('en-CA')
    return today <= cancellationEffectiveDate
  } else {
    return true
  }
}

export const TRIAL_DAYS = 14

export function getIsTrial(user: any) {
  const createdDaysOld = getCreatedDaysOld(user.createdAt)
  return createdDaysOld <= TRIAL_DAYS
}

export function getCreatedDaysOld(createdDate: string) {
  return differenceInDays(new Date(), new Date(createdDate))
}
