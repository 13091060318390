const isProd = true || process.env.NODE_ENV === 'production'
let peopleToSet

const analytics = {
  track: (name, props) => {
    if (!isProd) {
      return
    }

    if (window.splitbee) {
      if (peopleToSet) {
        window.splitbee.user.set(peopleToSet)
        peopleToSet = null
      }

      window.splitbee.track(name, props)
    }
  },
  identify: (props) => {
    if (!isProd) {
      return
    }

    const userToTrack = {
      userId: props.USER_ID,
      displayName: props.$name,
      email: props.$email,
      planType: props.planType,
    }

    if (window.splitbee) {
      window.splitbee.user.set(userToTrack)
    } else {
      peopleToSet = userToTrack
    }
  },
}

export default analytics
