import Dropdown from '@/components/Dropdown'
import {
  MoreHorizontal,
  FileText,
  BookOpen,
  Book,
  Clipboard,
  Youtube,
} from 'react-feather'
import { copyValueToClipBoard } from '@/utils/index'
import analytics from '@/utils/analytics'
import toast from 'react-hot-toast'
import getVideoMd from '@/utils/getVideoMd'
import Spinner from '@/components/Spinner'
import Button from '@/components/Button'
import getYouTubeTimestamps from '@/utils/getYouTubeTimestamps'
import { modalState } from '@/store'
import { useRecoilState } from 'recoil'
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  Link,
  View,
  StyleSheet,
} from '@react-pdf/renderer'
import { getTimeFromSeconds } from '@/utils/index'
import { useState, useEffect } from 'react'

function AnnotationsPdf({ video, annotations }) {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    videoTitle: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 20,
      fontWeight: 'bold',
    },
    annotationTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      marginLeft: 12,
      marginTop: 15,
      fontFamily: 'Times-Roman',
    },
    startTime: {
      fontSize: 16,
      marginLeft: 12,
      marginTop: 15,
      fontFamily: 'Times-Roman',
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    footer: {
      position: 'absolute',
      bottom: 50,
      left: 0,
      right: 0,
      textAlign: 'center',
      fontSize: 12,
      color: 'grey',
    },
    logo: {
      width: 15,
      height: 15,
      marginHorizontal: 4,
    },
  })

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Text style={styles.videoTitle}>
          {video.title} ({getTimeFromSeconds(video.duration)})
        </Text>

        {annotations.map((annotation) => (
          <View key={annotation._id} style={styles.section} wrap={false}>
            <Text style={styles.startTime}>
              {getTimeFromSeconds(annotation.start)}
            </Text>
            <Text style={styles.annotationTitle}>{annotation.title}</Text>
            <Text style={styles.text}>{annotation.content}</Text>
          </View>
        ))}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />

        <Text style={styles.footer} fixed>
          Powered by{' '}
          <Link href="https://annotate.tv" target="_blank">
            Annotate.tv
          </Link>
        </Text>
      </Page>
    </Document>
  )
}

function DownloadAnnotationsModalBody({ video, annotations }) {
  const [showDownload, setShowDownload] = useState(false)

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setShowDownload(true)
    }, 300)

    return () => clearTimeout(timeoutID)
  }, [])

  if (!showDownload) {
    return (
      <div className="w-full center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="w-full center">
      <PDFDownloadLink
        document={<AnnotationsPdf {...{ video, annotations }} />}
        fileName={`${video.title}.pdf`}
      >
        {({ loading }) => (loading ? <Spinner /> : <Button>Download</Button>)}
      </PDFDownloadLink>
    </div>
  )
}

export default function VideoMoreOptions({
  className = '',
  annotations,
  video,
}) {
  const [, setModal] = useRecoilState(modalState)

  function handleMenuClick(key) {
    switch (key) {
      case 'copy-md':
        const md = getVideoMd({ video, annotations })

        copyValueToClipBoard(md)

        analytics.track('Markdown copied')
        toast.success('Markdown copied')
        break

      case 'copy-yt':
        const timestamps = getYouTubeTimestamps({ annotations })

        copyValueToClipBoard(timestamps)

        analytics.track('Markdown copied')
        toast.success('Markdown copied')
        break

      case 'md-preview':
        analytics.track('MD previewed')
        window.open(
          `${window.document.location.origin}/api/videos/${video._id}/md`
        )
        break

      case 'pdf-download':
        analytics.track('PDF downloaded')
        window.open(
          `${window.document.location.origin}/api/videos/${video._id}/pdf?download=true`
        )
        break

      case 'csv': {
        // https://readwise.io/import_bulk
        analytics.track('Exported to CSV')

        const headers = [
          'Highlight',
          'Title',
          'Author',
          'URL',
          'Note',
          'Location',
        ]

        const csv = annotations.reduce((acc, annotation) => {
          const highlight = escapeDoubleQuotes(
            annotation.title || annotation.content
          )
          const title = escapeDoubleQuotes(video.title)
          const author = escapeDoubleQuotes(video.channelTitle)
          const url = video.url
          const note = escapeDoubleQuotes(annotation.content)

          return `${acc}\n"${highlight}","${title}","${author}",${url},"${note}",""`
        }, headers.join(','))

        const hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = `${video.title}.csv`
        hiddenElement.click()
        break
      }
      case 'pdf-preview':
        analytics.track('PDF previewed')
        setModal({
          title: 'Download annotations',
          body: <DownloadAnnotationsModalBody {...{ video, annotations }} />,
        })
        break

      default:
        break
    }
  }

  const mainOptions = [
    {
      name: 'Copy Markdown',
      value: 'copy-md',
      isPremium: true,
      icon: (
        <Clipboard className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Export Markdown',
      value: 'md-preview',
      isPremium: true,
      icon: (
        <FileText className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Export PDF',
      value: 'pdf-preview',
      isPremium: true,
      icon: (
        <BookOpen className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Export CSV',
      value: 'csv',
      isPremium: true,
      icon: (
        <Book className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
    {
      name: 'Copy YouTube times',
      value: 'copy-yt',
      isPremium: true,
      icon: (
        <Youtube className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      ),
    },
  ]

  return (
    <Dropdown
      options={mainOptions}
      onChange={handleMenuClick}
      className={className}
      position="left"
    >
      <div className="flex center px-1 py-1 bg-lighterblue text-primary rounded-md clickable-opacity">
        <MoreHorizontal className="text-primary" />
      </div>
    </Dropdown>
  )
}

function escapeDoubleQuotes(str) {
  return str.replace(/\\([\s\S])|(")/g, "'")
}
