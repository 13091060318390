import { RefObject, useEffect } from 'react'

export default function useOnClickOutside({
  container,
  show,
  setShow,
}: {
  container: RefObject<HTMLElement>
  show: boolean
  setShow: (show: boolean) => void
}) {
  useEffect(() => {
    function handleOutsideClick(event: MouseEvent | TouchEvent) {
      if (container.current && show) {
        if (!container.current.contains(event.target as Node)) {
          setShow(false)
        }
      }
    }

    window.addEventListener('mousedown', handleOutsideClick)
    return () => window.removeEventListener('mousedown', handleOutsideClick)
  }, [show, container, setShow])
}
